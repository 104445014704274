import React, { FC } from 'react';
import { Link } from 'gatsby';
import styles from './styles.module.css';

const QuickLinks: FC = () => (
  <div className={styles.quickLinks}>
    <Link to="/alaskaosp/">
      <div className={styles.image}>
        <img src="/alaskaosp.jpg" alt=""/>
        <span>Alaskaösp</span>
      </div>
    </Link>
    <Link to="/sitkagreni/">
      <div className={styles.image}>
        <img src="/sitkagreni.jpg" alt=""/>
        <span>Sitkagreni</span>
      </div>
    </Link>
    <Link to="/stafafura/">
      <div className={styles.image}>
        <img src="/stafafura.jpg" alt=""/>
        <span>Stafafura</span>
      </div>
    </Link>
    <Link to="/russalerki/">
      <div className={styles.image}>
        <img src="/russalerki.jpg" alt=""/>
        <span>Rússalerki</span>
      </div>
    </Link>
    <Link to="/birki/">
      <div className={styles.image}>
        <img src="/birki.jpg" alt=""/>
        <span>Ilmbjörk</span>
      </div>
    </Link>
  </div>
);

export default QuickLinks;