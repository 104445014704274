import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageSkograektin: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "skograektin-logo.jpg" }) {
        childImageSharp {
          fixed(width: 148, height: 26, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return <a href='https://www.skogur.is'><Img fixed={data.file.childImageSharp.fixed} /></a>;
};

export default ImageSkograektin;
