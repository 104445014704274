import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageLogo: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "carboncalculator.png" }) {
        childImageSharp {
          fixed(width: 51, height: 58, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.file.childImageSharp.fixed} />
}

export default ImageLogo
