import React, { FC } from "react"
import { Link } from "gatsby"
import { HeaderProps } from "./types"
import ThemeToggle from "../ThemeToggle"
import styles from "./styles.module.scss"
import ImageLogo from "../Image/logo"
import ImageSkogarkolefni from "../Image/skogakolefni"
import ImageSkograektin from "../Image/skograektin"

const Header: FC<HeaderProps> = ({ siteTitle, menuLinks }) => (
  <header className={styles.header}>
    <div className='contentWrap' >
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <ImageLogo />
          <div className={styles.logoText}>
            <span>SKÓGARKOLEFNIS-</span>
            <span>REIKNIR</span>
          </div>
        </Link>
        <div>
          <div className={styles.qn}>
            <div className={styles.qnItem}>
              <ImageSkograektin />
            </div>
            <div className={styles.qnItem}>
              <ImageSkogarkolefni />
            </div>
          </div>
          <nav className={styles.nav}>
            <ul style={{ display: "flex", margin: 0 }}>
              {menuLinks.map(link => (
                <li
                  className={styles.navItem}
                  key={link.name}
                  style={{
                    listStyleType: `none`,
                    padding: `10px`,
                  }}
                >
                  <Link to={link.link}>{link.name}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div className={styles.themeToggleContainer}>
      <ThemeToggle />
    </div>
  </header>
)

export default Header
