import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageSkogarkolefni: FC = () => {
  const data = useStaticQuery(graphql`
    query {
       file(relativePath: { eq: "skogarkolefni-logo.jpg" }) {
        childImageSharp {
          fixed(width: 109, height: 23, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <a href='https://www.skogarkolefni.is'><Img fixed={data.file.childImageSharp.fixed} /></a>
}

export default ImageSkogarkolefni
